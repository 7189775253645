

//添加外卖需求保存配置
export const saveTakeOut = {
    'qty1': {
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确用餐人数',
    }, 
    'amount': {
        required: true,
        description: '请填写正确预计金额',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额'               
    }
}

export const novartisSaveTakeOut = {
    'qty1': {
        required: true,
        description: '请填写正确用餐人数',
        regExp:/^[1-9]\d{0,3}$/,
        regExpMsg:'请填写正确用餐人数',
    }, 
    'amount': {
        required: true,
        description: '预计金额不能为空',
        regExp:/(^[1-9]\d{0,6}(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确预计金额'               
    },
    'mealSubType': {
        required: true,
        description: '订单类型不能为空'
    },
    'mealKind': {
        required: true,
        description: '用餐类型不能为空'
    },
    'hospital.hospitalName':{
        required: true,
        description: '医院不能为空'
    },
}

export const pfizerSaveTakeOut = {
    'itemName': {
        required: true,
        description: '请选择外卖类型',
    },
    'outPeople': {
      required: true,
      description: '请填写正确外部人数',
      regExp:/^\+?[1-9][0-9]*$/,
      regExpMsg:'请填写正确外部人数'
    },
    'innerPeople': {
      required: true,
      description: '请填写正确内部人数',
      regExp:/^\+?[1-9][0-9]*$/,
      regExpMsg:'请填写正确内部人数'
    },
    'amount': {
        required: true,
        description: '请填写总金额',
        regExp:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^(0){1}$)|(^\d\.\d{1,2}?$)/,
        regExpMsg:'请填写正确总金额'
    }
}






