 <template>
    <div class="addTakeOutDemand">
        <Header backType="white" :back="true"/>
        <div class="publicHead">
            <p>外卖</p>
            <Tips :tips="tips" />
        </div>
        <div>
            <div class="normalInputRow">
                <MInput title="用餐人数" pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <Btn btnClass="submitBtn" v-if="hideshow" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
import { getCookie } from 'tiny-cookie'
export default {
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
            },
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
            docmHeight: '0', 
            showHeight: 0, 
            hideshow: true, 
            isResize: false,
            optionalType: []
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    created(){
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
        }
        // 疾控提示
        if(['线上培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push({
                txt: '每场会议餐标预算400元'
            })
        }
        if(['主题培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push({
                txt: '每场会议餐标预算5000元'
            })
        }
        if(this.event.tenant_name == '会引擎-huarun'){
            this.tips.push({
                txt: '【确认发票】：查看是否有可开发票标签，如无标签，需下单前咨询商户确认可开票；'
            })
        }
    },
    async mounted(){
        // this.optionalType = await this.getWaimaiType()
        let platForm = navigator.userAgent.toUpperCase();
        let isAndroid = platForm.indexOf('ANDROID') > -1 || platForm.indexOf('LINUX') > -1;
        if(isAndroid){
            window.addEventListener('resize',() => {
                if (!this.isResize) {
                    this.docmHeight = window.document.documentElement.clientHeight || window.innerHeight
                    this.isResize = true
                }
                //实时屏幕高度
                this.showHeight = window.document.documentElement.clientHeight || window.innerHeight
            })
        }
    },
    watch: {
        showHeight: function() {
            if (this.docmHeight !== this.showHeight) {
                this.hideshow = true
            } else {
                this.hideshow = false
            }
        }
    },
    methods: {
        submit() {
            let params = {
                ...this.input,
            };
            params.proposalId = this.event.proposalId;
            params.itemId = this.orderDetail.itemId;
            let validateResult = this.validate(params, saveTakeOut);
            if (!validateResult) return;
            params.ItemType = '7';
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.push(
                        `/orderList`
                    );
                }
            });
        },
        // 获取外卖类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: getCookie('tenant_code') || this.event.tenant_code || "crossevent",
                    mealType: "外卖",
                    tenant_id: getCookie('tenant_id')
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || { optionalType: [] }).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
};
</script>
<style scoped lang="scss">
.addTakeOutDemand {
    .submitBtn {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 80%;
        border-radius: 0.25rem;
        position: absolute;
        bottom: 0.34rem;
        right: 10%;
        font-size: 0.14rem;
    }
}
</style>