<template>
    <div class="addTakeOutDemand">
        <Header backType="white" :back="true"/>
        <div class="publicHead">
            <p>外卖</p>
            <Tips :tips="tips" />
        </div>
        <div>
            <div class="normalInputRow">
                <MInput title="用餐人数" pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <div class="btnRow">
                <div class="btnRowTitle">订单类型<span>*</span></div>
                <div class="btnRowGroup">
                    <Btn
                        v-for="(item, index) in orderTypeList"
                        :key="index"
                        :btnClass="input.mealSubType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="input.mealSubType = item.txt "
                    >
                        <span slot="btnContent">{{item.txt}}</span>
                    </Btn>
                </div>
            </div>
            <div class="btnRow">
                <div class="btnRowTitle">用餐类型<span>*</span></div>
                <div class="btnRowGroup">
                    <Btn
                        v-for="(item, index) in mealTypeList"
                        :key="index"
                        :btnClass="input.mealKind == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="input.mealKind = item.txt "
                    >
                        <span slot="btnContent">{{item.txt}}</span>
                    </Btn>
                </div>
            </div>
            <div class="normalInputRow" @click="chooseHos">
                <MInput title="选择医院" must="true" pla="请选择医院" :disabled="true" v-model="input.hospital.hospitalName"></MInput>
            </div>
        </div>
        <Btn btnClass="submitBtn" @callBack="submit">
            <span slot="btnContent">保存</span>
        </Btn>
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
import {
    novartisSaveTakeOut,
} from "@/config/saveTakeOut.js";
export default {
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
                mealKind: '',
                mealSubType:'',
                hospital:{
                    hospitalName:''
                }
            },
            orderTypeList:[
                {txt: '外卖用餐'},
                {txt: '外卖茶歇'},
            ],
            mealTypeList:[
                {txt: '午餐'},
                {txt: '晚餐'},
            ],
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"]),
    },
    created(){
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
            this.input.itemId = this.$route.query.itemId
            let itemData = JSON.parse(this.$route.query.itemData)
            this.input.mealKind = itemData.MealKind
            this.input.mealSubType = itemData.MealSubType
            this.input.hospital = itemData.Hospital
        }
    },
    methods: {
        // 选择医院
        chooseHos() {
            this.$refs.hospitalPop.openPop()
        },
        chooseHosRes(item) {
            this.input.hospital = {
                longitude: item.el,
                latitude: item.nl,
                hospitalName: item.name,
                rowId: item.rowId
            }
        },
        submit() {
            let params = {
                ...this.input,
                // itemName: "饿了么外卖",
                // sourceCode: "ELE"
            };
            params.proposalId = this.event.proposalId;
            let validateResult = this.validate(params, novartisSaveTakeOut);
            if (!validateResult) return;
            // if((this.input.amount/this.input.qty1) > 100) {
            //     this.toast('外卖人均不能超100元/人')
            //     return
            // }
            params.ItemType = '7';
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.back();
                }
            });
        }
    }
};
</script>
<style lang="scss">
// @import "../addMealDemand/addMealDemand.scss";

.addTakeOutDemand {
    .btnRow {
        margin: 0.15rem 0.25rem 0 0.25rem;
        position: relative;

        .btnRowTitle {
            text-align: left;
            font-size: 0.14rem;
            color: #90939a;
            span {
                color: #d60012;
                margin-left: 0.04rem;
            }
        }
        .btnRowGroup {
            overflow: hidden;
            .btnRdio {
                height: 0.36rem;
                line-height: 0.36rem;
                float: left;
                width: 25%;
                margin: 0.1rem 0.1rem 0.1rem 0;
                border-radius: 0.33rem;
                font-size: 0.12rem;
            }

            .btnRdioDis {
                background: #f5f5f5 !important;
                color: #13161b !important;
            }
        }
    }
    .submitBtn {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 80%;
        border-radius: 0.25rem;
        position: fixed;
        bottom: 0.34rem;
        right: 10%;
        font-size: 0.14rem;
    }
}
</style>