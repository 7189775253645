<template>
    <component :is="addTakeOutDemandx"></component>
</template>
<script>
import { mapGetters } from "vuex";
import addTakeOutDemand_crossevent2 from '@/template/AddTakeOutDemand/crossevent2.vue'
import addTakeOutDemand_crossevent from '@/template/AddTakeOutDemand/crossevent.vue'
import addTakeOutDemand_dscl from '@/template/AddTakeOutDemand/dscl.vue'
import addTakeOutDemand_novartis from '@/template/AddTakeOutDemand/novartis.vue'
// import addTakeOutDemand_pfizer from '@/template/AddTakeOutDemand/pfizer.vue'
import addTakeOutDemand_pfizer from '@/template/AddTakeOutDemand/crossevent.vue'
import addTakeOutDemand_pfizer2 from '@/template/AddTakeOutDemand/crossevent2.vue'
export default {
    components: {
        addTakeOutDemand_dscl,
        addTakeOutDemand_pfizer,
        addTakeOutDemand_crossevent,
        addTakeOutDemand_novartis,
        addTakeOutDemand_crossevent2,
        addTakeOutDemand_pfizer2
        
    },
    computed: {
        ...mapGetters(['event','mealRules']),
        addTakeOutDemandx: {
            get: function() {
                var componentId = this.event.tenant_code || 'eventcool';
                if(this.mealRules.newUI){
                    componentId =  'crossevent2'
                }
                return ('addTakeOutDemand_' + componentId);  
            }    
        }
    },
}
</script>
