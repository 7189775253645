 <template>
    <div class="addTakeOutDemand2">
        <Header backType="white" :back="true" :backEvent="true" @backEvent="$router.push('/orderList')">
            <span slot="headText" style="font-size:.17rem;">添加外卖需求</span>
        </Header>
        <div class="addMealDemand-container">
            <AttentionTips :tips="tips" :newUI="true" />
            <div class="btnRow" v-if="event.tenant_code == 'pfizer' && event.typeDictTxt == '患者教育会'">
                <div class="btnRowTitle">是否为饮品费<span>*</span></div>
                <div class="btnRowGroup">
                    <Btn
                        :btnClass="input.isDrink === true ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="input.isDrink = true"
                    >
                        <span slot="btnContent">是</span>
                    </Btn>
                    <Btn
                        :btnClass="input.isDrink === false ? 'btnRdio':'btnRdio btnRdioDis'"
                        @callBack="input.isDrink = false"
                    >
                        <span slot="btnContent">否</span>
                    </Btn>
                </div>
            </div>
            <div class="normalInputRow2" v-if="['会引擎-灵北'].includes(this.event.tenant_name)" @click="lbMethod">
                <MInput title="用餐编号" :labelBlack="true" :disabled="true" :borderBottom="true" must="true" type='tel' pla="请选择用餐编号" v-model="input.budgetNum"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="用餐人数" :labelBlack="true" type='tel' pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" :labelBlack="true" type='number' pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <div  v-if="['novartis'].includes(event.tenant_code)">
                <div class="btnRow">
                    <div class="btnRowTitle">订单类型<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in orderTypeList"
                            :key="index"
                            :btnClass="input.mealSubType == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="input.mealSubType = item.txt ">
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="btnRow">
                    <div class="btnRowTitle">用餐类型<span>*</span></div>
                    <div class="btnRowGroup">
                        <Btn
                            v-for="(item, index) in mealTypeList"
                            :key="index"
                            :btnClass="input.mealKind == item.txt ? 'btnRdio':'btnRdio btnRdioDis'"
                            @callBack="input.mealKind = item.txt ">
                            <span slot="btnContent">{{item.txt}}</span>
                        </Btn>
                    </div>
                </div>
                <div class="normalInputRow" @click="chooseHos">
                    <MInput title="选择医院" must="true" pla="请选择医院" :labelBlack="true" :disabled="true" v-model="input.hospital.hospitalName"></MInput>
                </div>
            </div>
            
            <div class="normalInputRow"
                v-if="event.statusTxt == '计划草稿' && event.tenant_code == 'pfizer'">
                <MInput title="备注" must='true' :labelBlack="true" pla="请备注说明类型（午餐/晚餐/茶歇）、用餐人数" v-model="input.BudgetDescr"></MInput>            
            </div>
            <Btn btnClass="submit_btn" v-if="hideshow" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
        <div v-if='budgetListShow' style="position: fixed;left: 0;top: 0;z-index: 999999;background: white;height: 100%;width: 100%;">
            <Header backType="white" :back="true" :backEvent="true" @backEvent="budgetListShow = false" />
            <searchRow placeholder="请输入用餐编号搜索" v-model="budgetkeyword" @change='searchbudgetList'></searchRow>
            <div v-if='true'
                style="position: absolute;top: 0px;left: 50%;transform: translateX(-50%);z-index: 2222;line-height: 0.4rem;">
                <p style="font-size: 18px;">选择预算编号</p>
            </div>
            <div style='height: calc(100% - 90px);overflow-y: auto;box-sizing: border-box;padding: 28px;padding-bottom: 107px;'>
                <div v-for='(item,index) in budgetListData' :key="index">
                    <div style='border-bottom: 1px solid #ccc;padding: 10px 0px;position: relative;'
                        @click='()=>{input.budgetNum = item.itemNum;input.budgetRemark = item.remark;input.amount = item.amount;budgetListShow = false}'
                        >
                        <p style='text-align:left'>用餐编号：{{item.itemNum}}</p>
                        <p style='text-align:left'>描述：{{item.remark}}</p>
                        <p style='text-align:left'>申请预算：{{item.amount}}</p>
                        <span v-if='input.budgetNum == item.itemNum'
                            style="position: absolute; top: 50%; right: 0px; transform: translateY(-50%);">
                            <Svgs color="#ff0000" name="icondingdanwancheng"></Svgs>
                        </span>
                    </div>
                </div>
                <NoData v-if="!budgetListData.length"></NoData>
            </div>
        </div>
        <HospitalPop @onOk="chooseHosRes" ref="hospitalPop" />
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
import { getCookie } from 'tiny-cookie'
export default {
    data() {
        return {
            budgetListShow: false,
            input: {
                qty1: "",
                amount: "",
                mealKind: '',
                mealSubType:'',
                hospital:{
                    hospitalName:''
                },
                isDrink: false
            },
            tips: ["需求信息（*为必填项）" ],
            docmHeight: '0', 
            showHeight: 0, 
            hideshow: true, 
            isResize: false,
            optionalType: [],
            budgetListData: [],
            orderTypeList:[
                {txt: '外卖用餐'},
                {txt: '外卖茶歇'},
            ],
            mealTypeList:[
                {txt: '午餐'},
                {txt: '晚餐'},
            ],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    async created(){
        if(['会引擎-灵北'].includes(this.event.tenant_name)){
            this.input.qty1 = this.event.extData.qty
            let initBudgetList = await miceService.common('/api/jeventorder/budget/budgetList',{proposalId:this.event.proposalId})
            if (initBudgetList && initBudgetList.content) {
                this.budgetListData = initBudgetList.content
            }
        }
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
            if(['会引擎-灵北'].includes(this.event.tenant_name)){
                let itemData = JSON.parse(this.orderDetail.itemData)
                this.input.budgetRemark = itemData.budgetRemark
                this.input.budgetNum = itemData.budgetNum
            }
            if(this.event.tenant_code == 'novartis'){
                let itemData = JSON.parse(this.$route.query.itemData)
                this.input.mealKind = itemData.MealKind
                this.input.mealSubType = itemData.MealSubType
                this.input.hospital = itemData.Hospital

            }
            if(this.event.tenant_code == 'pfizer'){
                this.input.BudgetDescr = this.orderDetail.descr || ''
            }
        }
        // 疾控提示
        if(['线上培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push('每场会议餐标预算400元')
        }
        if(['主题培训会'].includes(this.event.typeDictTxt) && this.event.tenant_name == '会引擎-疾控'){
            this.tips.push('每场会议餐标预算5000元')
        }
        if(this.event.tenant_name == '会引擎-huarun'){
            this.tips.push('【确认发票】：查看是否有可开发票标签，如无标签，需下单前咨询商户确认可开票；')
        }
        if(this.event.tenant_code == 'pfizer'){
            this.tips = [
                '* 请同事们自行与商家和配送人员确认派送时间及其他细节安排',
                '* 外卖购买时，请注意消费的合规合理：禁止购买任何礼品卡或代金券等类似现金等价物商品，禁止购买任何风俗礼品、礼盒、鲜花以及果篮等不合规商品；', 
                '* 请提醒商家开具发票，发票抬头：智会云（天津）会议展览有限公司；纳税人识别号：91120222MA069UF33M', 
                '* GE的人均费用为80元'
            ]
        }
    },
    async mounted(){
        // this.optionalType = await this.getWaimaiType()
        let platForm = navigator.userAgent.toUpperCase();
        let isAndroid = platForm.indexOf('ANDROID') > -1 || platForm.indexOf('LINUX') > -1;
        if(isAndroid){
            window.addEventListener('resize',() => {
                if (!this.isResize) {
                    this.docmHeight = window.document.documentElement.clientHeight || window.innerHeight
                    this.isResize = true
                }
                //实时屏幕高度
                this.showHeight = window.document.documentElement.clientHeight || window.innerHeight
            })
        }
    },
    watch: {
        showHeight: function() {
            if (this.docmHeight !== this.showHeight) {
                this.hideshow = true
            } else {
                this.hideshow = false
            }
        }
    },
    methods: {
        lbMethod(){
            if(this.orderDetail.itemId){
                return
            }
            this.budgetListShow = true
        },
        // 选择医院
        chooseHos() {
            this.$refs.hospitalPop.openPop()
        },
        chooseHosRes(item) {
            this.input.hospital = {
                longitude: item.el,
                latitude: item.nl,
                hospitalName: item.name,
                rowId: item.rowId
            }
        },
        async searchbudgetList(){
            this.input.budgetNum = null
            let res = await miceService.common('/api/jeventorder/budget/budgetList',{proposalId:this.event.proposalId,itemNum:this.budgetkeyword})
            if (res && res.success) {
                this.budgetListData = res.content
                this.$forceUpdate()
            }
        },
        submit() {
            let params = {
                ...this.input,
            };
            params.proposalId = this.event.proposalId;
            params.itemId = this.orderDetail.itemId;
                
            if(!this.input.budgetNum && ['会引擎-灵北'].includes(this.event.tenant_name)){
                this.toast('用餐编号不能为空')
                return
            }
            let validateResult = this.validate(params, saveTakeOut);
            if (!validateResult) return;
            params.ItemType = '7';
            if(!this.input.BudgetDescr && this.event.statusTxt == '计划草稿' && this.event.tenant_code == 'pfizer'){
                this.toast('请填写备注')
                return
            }
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.push(
                        `/orderList`
                    );
                }
            });
        },
        // 获取外卖类型
        getWaimaiType() {
            let params = {
                collection: "cfg-imeal-channel",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenantCode: getCookie('tenant_code') || this.event.tenant_code || "crossevent",
                    mealType: "外卖",
                    tenant_id: getCookie('tenant_id')
                },
                projection: {
                    optionalType: 1,
                }
            };
            return new Promise((resolve, reject) => {
                miceService.getOptItems(params).then(res => {
                    if (res && res.success && res.content) {
                        resolve((res.content[0] || { optionalType: [] }).optionalType)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
    }
};
</script>
<style lang="scss">
.addTakeOutDemand2 {
    width: 100%;
    
    .btnRow {
        margin: 0.15rem 0.25rem 0 0.25rem;
        position: relative;

        .btnRowTitle {
            text-align: left;
            font-size: 0.15rem;
            color: #13161B;
            span {
                color: #d60012;
                margin-left: 0.04rem;
            }
        }
    }
    .btnRowGroup {
        overflow: hidden;
        .btnRdio {
            height: 0.36rem;
            line-height: 0.36rem;
            float: left;
            width: 25%;
            margin: 0.1rem 0.1rem 0.1rem 0;
            border-radius: 0.33rem;
            font-size: 0.12rem;
        }

        .btnRdioDis {
            background: #f5f5f5 !important;
            color: #13161b !important;
        }
    }
    .addMealDemand-container {
        position: fixed;
        overflow: scroll;
        top: 0.4rem;
        bottom: 0;
        width: 100%;
        padding-bottom: 1rem;
        .normalInputRow{
            input{
                font-size: .15rem!important;
            }
            input::placeholder{
                color: #909399!important;
            }
        }
    }
    .submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
    }
}
</style>