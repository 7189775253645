<template>
    <div class="addTakeOutDemand">
        <Header backType="white" :back="true"/>
        <div class="publicHead">
            <p>外卖</p>
            <Tips :tips="tips" />
        </div>
        <div>
            <div class="normalInputRow">
                <MInput title="用餐人数" pla="请输入用餐人数" must='true' v-model="input.qty1"></MInput>
            </div>
            <div class="normalInputRow">
                <MInput title="预计金额" pla="请输入预计金额" must='true' v-model="input.amount"></MInput>
            </div>
            <Btn btnClass="submitBtn" @callBack="submit">
                <span slot="btnContent">保存</span>
            </Btn>
        </div>
    </div>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { saveTakeOut } from "@/config/saveTakeOut.js";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            input: {
                qty1: "",
                amount: "",
            },
            tips: [{
                txt: "需求信息（*为必填项）" 
            }],
        };
    },
    computed: {
        ...mapGetters(["event", "orderDetail"])
    },
    created(){
        if(this.$route.query && this.$route.query.isEdit){
            this.input.qty1 = this.$route.query.qty1
            this.input.amount = this.$route.query.amount
        }
    },
    methods: {
        submit() {
            let params = {
                ...this.input,
                // itemName: "饿了么外卖",
                // sourceCode: "ELE"
            };
            params.proposalId = this.event.proposalId;
            params.itemId = this.orderDetail.itemId;
            let validateResult = this.validate(params, saveTakeOut);
            if (!validateResult) return;
            // if((this.input.amount/this.input.qty1) > 100) {
            //     this.toast('外卖人均不能超100元/人')
            //     return
            // }
            params.ItemType = '7';
            miceService.saveTakeOutItem(params).then(res => {
                if (res && res.success) {
                    this.$router.back();
                }
            });
        }
    }
};
</script>
<style lang="scss">
.addTakeOutDemand {
    .submitBtn {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        width: 80%;
        border-radius: 0.25rem;
        position: absolute;
        bottom: 0.34rem;
        right: 10%;
        font-size: 0.14rem;
    }
}
</style>